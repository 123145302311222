@import "src/styles/theme.scss";

.input-box{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  left: 10%;
  top: 30%;
  padding: 10px;
}

.input-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

}

.verify-wrapper{
  position: absolute;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  background-color: $color-assignment-background;
  border: solid black 1px;
  padding: 0;
}

.verify-table{
  display: block;
  padding: 0;
  height: calc(100% - 50px);
  width: 100%;
  overflow-y: scroll;
}
.verify-thead{
  display: table;
  height: 50px;
  width: 100%;
}

.verify-tbody{
  display: table;
  overflow-y: scroll;
  width: 100%;
}
