.screen{
  position:relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 25vw;
  padding-top: 35vh;
}

.form{
  // position:relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 30%;
  border: solid black 1px;
  padding: 10px;
  // left: 25%;
  // top: 35%;
}

.inputs{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  //left: 25%;
  // justify-self:right;
}

.input-row{
  display: flex;
  flex-direction: row;
}

.login-username{
  display: flex;
  margin: 10px;
  height: 40px;
  width: 100%;
}

.login-password{
  display: flex;
  margin: 10px;
  height: 40px;
  width: 100%;
}

.login-usergroup{
  display: flex;
  margin: 10px;
  height: 40px;
  width: 30%;
}

.button{
  display: flex;
  //position: relative;
  //left: 80%;
  width: 40%;
  margin-top: 10px;
  height: 45px;
  z-index: 5;
}

@media screen and (max-width: 600px) {
  .screen {
    padding-left: 1vw;
  }
 .form {
   width: 95%;
 }

}
