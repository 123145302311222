@import "src/styles/theme.scss";

.home-wrapper{
  width: 100vw;
  height: 100vh;
  //background: url(../resources/background.png);
  //background-image: $background;
  //background-size: cover;
}

.home-content{
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.home-links{
  display: flex;
  flex-direction: column;
  margin-left: 20%;
  width: 60%;
}

.home-link{
  display: inline-block;
  height: 50px;
  text-align: center;
}
