@import "src/styles/theme.scss";

.wrapper{
  display: inline-block;
  white-space: nowrap;
}

.dropdown-input{

}

.options{
  position: absolute;
  z-index: 15;
  padding: 10px;
  margin-left: 10px;
  border: solid black 1px;
  background: #FFFFFF;
}

.option{
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.option-active{
  background-color: $color-dropdown-active;
}

.option:hover{
  background-color: $color-dropdown-hover;
}
