@import "src/styles/theme.scss";

.search-bar{
  display: inline-block;
  font-size: large;
}

.assignment-wrapper{
  position: absolute;
  //display: flex;
  //justify-content: space-between;
  //align-content: space-between;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  background-color: $color-assignment-background;
  border: solid black 1px;
  padding: 0;
}

.assignment-table{
  display: block;
  padding: 0;
  height: calc(100% - 50px);
  width: 100%;
  overflow-y: scroll;
}
.assignment-thead{
  display: table;
  height: 50px;
  width: 100%;
}

.assignment-tbody{
  display: table;
  overflow-y: scroll;
  width: 100%;
  //max-height: calc(100px);

}

.assignment-row{
  //display: table;
  //table-layout: fixed;
  align-items: start;
  justify-content:start;
  height: 50px;
}

.assignment-row-permission-active{
  //background-color: $color-assignment-active;
}

.assignment-row-delete:hover{
  //background-color: $color-assignment-active;
}

.assignment-dropdown{
  position: absolute;
  width: 200px;
  margin-left: 10px;
  margin-top: 10px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 5;
  border: solid black 1px;
  background-color: $color-dropdown-bg;
  font-weight:normal;
}

.assignment-dropdown-option:hover{
  background-color: $color-dropdown-hover;
}

.assignment-new-wrapper-outer{
  position: relative;
}

.assignment-new-wrapper{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: fit-content;
  top: 10px;
  left: calc(20px);
  padding: 10px;
  z-index: 5;
  border: solid black 1px;
  background-color: $color-dropdown-bg;
  font-weight:normal;
}

.assignment-new-option:hover{
  background-color: $color-dropdown-hover;
}

.assignment-row-user{
  text-align: left;
}

.assignment-row-permission{
  text-align: center;
}

.assignment-row-permission-hint{
  display: inline-block;
  position: fixed;
  height: 30px;
  line-height: 30px;
  border: solid black 1px;
  border-radius: 10px;
  padding: 5px;
  background-color: #FFFFFF;
  right: 10%;
  margin-top: -10px;
  animation: fadeOut 3s;
  animation-fill-mode: forwards;
}

.assignment-row-delete{
  text-align: center;
}

.assignment-row-permission:hover{
  background-color: $color-cell-active;
}

.assignment-row-delete:hover{
  background-color: $color-cell-active;
}

.assignment-row-user:hover{
  background-color: unset;
}

.assignment-row:nth-child(odd) {
  background-color: $color-table-odd;
}

.assignment-row:nth-child(even) {
  //background-color: $color-table-even;
}

.assignment-cell-inactive:hover{
  background-color: unset;
}

.assignment-button-row {
  display: grid;
  flex-direction: row;
  width: 100%;
  height: 50px;
  bottom: 0;
  position: absolute;
}

.assignment-button-row-close {
  justify-self: right;
  width: 100px;
  //height: 100%;
  height: 40px;
  margin: 5px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
