.windowWrapper {
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: absolute;
  top: 0;
}

.genericElement {
  font-family: 'Roboto', sans-serif;
}
