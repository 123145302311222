.loading-overlay{
  position: absolute;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: #79797966;
}

.loading-dots{
  position: fixed;
  width: 40%;
  top: 40%;
  left: 30%;
}
