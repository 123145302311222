@import "src/styles/theme.scss";

.header-wrapper{
  width: 100%;
  height: 50%;
  margin: auto;
  // overflow:hidden;
  // white-space: nowrap;
}

.search-bar-wrapper{

}

.search-bar{
  // width: 80%;
  display: inline-block;
  font-size: large;
  // -moz-box-sizing: content-box; /* or `border-box` */
  // -webkit-box-sizing: content-box;
  // box-sizing: content-box;
}

.limit-input {
  width: 10%;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-left: 1%
  //height: 10%;
}

.sort-button{
  display: inline-block;
  font-size: large;
  height: 27px;
  width: 27px;
  padding: 1px;
  // height: 100%;
  // -moz-box-sizing: content-box; /* or `border-box` */
  // -webkit-box-sizing: content-box;
  // box-sizing: content-box;
}

.header-checkboxes, .row-checkbox{
  width: 10%;
}

.row-checkbox{
  text-align: center;
}

.offset{
  margin-top: 5px;
  margin-bottom: 2px;
}

.row-offset-slider-box{
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background: black;
}

.row-offset-slider{


  width: 20px;
  height: 20px;
  background-color: #FFF;
  border-radius: 50%;
  border-color: #000;
}

.table-row-header th{
  border-bottom: solid rgb(102, 102, 102) 2px;
}

.offset{
  margin-top: 5px;
  margin-bottom: 2px;
}

.selection-button{
  width: 100%;
}

.dropdown-placeholder{
  display: flex;
  width: 100%;
}

.dropdown-options{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 50vh;
  overflow: scroll;
  top: 10px;
  left: calc(-50% + 20px);
  z-index: 5;
  border: solid black 1px;
  background-color: $color-dropdown-bg;
  font-weight:normal;
}

.dropdown-option:hover{
  background-color: $color-dropdown-hover;
}

.assign-bar{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 5%;
}

.assign-button{
  width: 30%;
  height: 100%;
}

.assign-dropdown{
  position: absolute;
  bottom: 0;
  max-height: 50vh;
  overflow: scroll;
  margin-left: 25px;
  width: calc(30% - 52px);
  background-color: $color-dropdown-bg;
  border: solid black 1px;
  border-radius: 0;
}

.assign-option{
  height: 30px;
}

.assign-option:hover{
  background-color: $color-dropdown-hover;
}

.import-hide{
  //position: static;
  display: none;
}

.import-button{
  display: inline-block;
  height: 100%;
  width: 100%;
  line-height: 300%;
}

// .Dropdown-control{
//   display: flex;
//   height: 100%;
//   width: 100%;
// }

// .Dropdown-menu{
//   position:relative;
//   display: flex;
//   flex-direction: column;
//   // left: 0%;
//   margin-left: calc(-100% + 30px);
//   margin-top: 10px;
//   z-index: 5;
//   border: solid black 1px;
//   // color: wheat;
//   background-color: #e9e9e9;
//   width: 100%;
//   height: fit-content;
// }

.table {
  padding-bottom: 50px;
}
