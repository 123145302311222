@import "src/styles/theme.scss";

.search-bar{
  display: inline-block;
  font-size: large;
}

.table-cell-wide{
  width: 20%;
}

.table-cell-narrow{
  width: 15%;
}

.table-cell-narrower{
  //text-align: left;
  width: 15%;
}

.table-button{
  display: inline-block;
  background-color: #eeeeee;
  border: solid grey 1px;
  border-radius: 2%;
  padding: 5px;
  margin: 5px;
  height: 80%;
  width: 10vw;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  //margin: auto;
  cursor: pointer;
}

.table-button:hover{
  background-color: #cccccc;
  cursor: pointer;
}

.assignment-wrapper{
  position: absolute;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  background-color: $color-assignment-background;
  border: solid black 1px;
  padding: 0;
}

.assignment-table{
  display: block;
  padding: 0;
  height: calc(100% - 50px);
  width: 100%;
  overflow-y: scroll;
}
.assignment-thead{
  display: table;
  height: 50px;
  width: 100%;
}

.assignment-tbody{
  display: table;
  overflow-y: scroll;
  width: 100%;
}

.assignment-row{
  align-items: start;
  justify-content:start;
  height: 50px;
}

.assignment-row-permission-active{
  //background-color: $color-assignment-active;
}

.assignment-row-delete:hover{
  //background-color: $color-assignment-active;
}

.assignment-dropdown{
  position: absolute;
  width: 200px;
  margin-left: 10px;
  margin-top: 10px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 5;
  border: solid black 1px;
  background-color: $color-dropdown-bg;
  font-weight:normal;
}

.assignment-dropdown-option:hover{
  background-color: $color-dropdown-hover;
}


.assignment-new-wrapper-outer{
  position: relative;
}

.assignment-new-wrapper{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: fit-content;
  top: 10px;
  left: calc(20px);
  padding: 10px;
  z-index: 5;
  border: solid black 1px;
  background-color: $color-dropdown-bg;
  font-weight:normal;
}

.assignment-new-option:hover{
  background-color: $color-dropdown-hover;
}

.assignment-row-user{
  text-align: left;
}

.assignment-row-permission{
  text-align: center;
}

.assignment-row-delete{
  text-align: center;
}

.assignment-row-permission:hover{
  background-color: $color-cell-active;
}

.assignment-row-delete:hover{
  background-color: $color-cell-active;
}

.assignment-row-user:hover{
  background-color: unset;
}

.assignment-row:nth-child(odd) {
  background-color: $color-table-odd;
}

.assignment-row:nth-child(even) {
  //background-color: $color-table-even;
}

.assignment-cell-inactive:hover{
  background-color: unset;
}

.image-wrapper{
  position: absolute;
  width: 90vw;
  height: 90vh;
  left: 5%;
  top: 5%;
  border: solid black 1px;
  overflow: hidden;
  background-color: #e4e4e7;
  text-align: center;
}

.image{
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  left: 50%;
  //right: 0%;
  top: 50%;
  //bottom: 0%;
  //margin: auto;
}

.image-preview{
  display: inline-block;
  height: 100%;
  max-width: 100%;
  border: solid grey 1px;
  margin: 0;
  padding: 0;
  margin-top: 4px;
}

.location-wrapper{
  position: absolute;
  width: 50%;
  height: 90%;
  left: 25%;
  top: 5%;
  background-color: $color-assignment-background;
  border: solid black 1px;
  padding: 0;
  text-align: center;
}

.location-table{
  display: block;
  padding: 0;
  height: calc(100% - 50px);
  width: 100%;
  overflow-y: scroll;
}

.location-thead{
  display: table;
  height: 50px;
  width: 100%;
}

.location-tbody{
  display: table;
  overflow-y: scroll;
  width: 100%;
}

.location-row{
  align-items: start;
  justify-content:start;
  height: 50px;
}

.location-row{
  height: 50px;
}

.location-cell-name{
  width: 70%;
}

.location-cell-checkbox{
  width: 30%;
}

.location-checkbox{
  width: 30px;
  height: 30px;
}

.location-row:nth-child(odd) {
  background-color: $color-table-odd;
}

.location-row:nth-child(even) {
  //background-color: $color-table-even;
}

.assignment-button-row {
  display: grid;
  flex-direction: row;
  width: 100%;
  height: 50px;
  bottom: 0;
  position: absolute;
}

.assignment-button-row-close {
  justify-self: right;
  width: 100px;
  //height: 100%;
  height: 40px;
  margin: 5px;
}

.add-wrapper{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 10%;
  // -10px because of padding
  left: calc(35% - 10px);
  top: 20%;
  background-color: #999999;
  border: solid black 1px;
  padding: 10px;
  justify-content: space-evenly;
}

.add-buttons{
  display: flex;
  flex-direction: row;
}

.add-button{
  display: flex;
  width: 50%;
}


.link-wrapper{
  position: absolute;
  width: 90vw;
  height: 90vh;
  left: 5%;
  top: 5%;
  border: solid black 1px;
  background-color: #e4e4e7;
  text-align: center;
  //overflow: scroll;

}

.link-wrapper-inner{
  overflow-y: scroll;
  height: 90%;
}

.link-table{
}

.link-thead{

}

.link-tr-head{
  font-weight: bold;
}

.link-td-head{

}

.link-tbody{

}

.link-tr-body{

}

.link-td-body{

}

.link-image{
  height: 100px;
  min-width: 50px;
  width: 100px;
  max-width: 200px;
  object-fit: contain;
  border: solid black 1px;
}

.link-select-wrapper{
  position: absolute;
  width: 50%;
  height: 90%;
  left: 25%;
  top: 10%;
  background-color: #b7b7b7;
  border: solid black 1px;
  overflow-y: scroll;
}

.link-select-wrapper-inner{
  position: absolute;
  width: 100%;
  height: 90%;
  background-color: #b7b7b7;
  overflow-y: scroll;
}

.link-select-table{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.link-select-image-wrapper{
  display: flex;
  height: 200px;
  width: 200px;
  border: solid black 1px;
  margin: 10px;
}

.link-select-image{
  display: flex;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin: auto;
}

.link-button-row{
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - 10px);
  padding: 5px;
  text-align: right;
  border-top: solid black 1px;
}

.link-button{
  justify-self: right;
  width: 100px;
  //height: 100%;
  height: 40px;
  margin: 5px;
}
