.input-box{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  left: 10%;
  top: 30%;
  padding: 10px;
}

.input-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

}

.spacer{
  display: block;
  width: 40px;
}

.input-one{
  display: flex;
  width: 100%;
}

.input-two{
  display: flex;
  margin-right: 2.5%;

}

.input-serials{
  display: flex;
  width: 100%;
  height: 30px;
}

.button-row{
  display: flex;

}

.button-cell{
  display: flex;

}

.button{
  //display: flex;
  width: 200px;
  height: 40px;
  text-align: center;
}

.import-hide{
  //position: static;
  display: none;
}

.import-button{
  display: inline-block;
  height: 100%;
  width: 100%;
  line-height: 300%;
}
