@import "src/styles/theme.scss";

.value-input-string{
  width: 200px;
}

.value-input-number{
  width: 200px;

}

.value-input-missing{
  background-color: #FF4444;
}
.value-input-string,
.value-input-number,
.input-field {
  border: solid #ddd 1px;
}

.value-input-string:focus,
.value-input-number:focus,
.input-field:focus {
  outline: none;
  border-color: $main-color;
}
