@import "src/styles/theme.scss";

.add-wrapper{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 10%;
  // -10px because of padding
  left: calc(35% - 10px);
  top: 20%;
  background-color: #999999;
  border: solid black 1px;
  padding: 10px;
  justify-content: space-evenly;
}

.add-buttons{
  display: flex;
  flex-direction: row;
}

.add-button{
  display: flex;
  width: 50%;
}

.table{
  text-align: center;
}

.button{
  width: 80%;
  left: 10%;
  height: 40px;
}

.import-hide{
  //position: static;
  display: none;
}

.import-button-label,
.export-button,
.import-button{
  display: block;
  text-align: center;
  height: 100%;
  width: 80%;
  margin-left: 10%;
  line-height: 250%;
}

.td-head{
  width: 15%;
}

.td-button-outline{
  background: #FF5555;
}

.td-head-tag{
  width: 40%;
  font-weight: bold;
}
