$main-color: #A5CC00FF;
$color-white: #fff;
$color-bg: #eee;
$color-bg-head: #e2e2e2;
$color-fadein: #ccffaa9d;
$color-cell-active: rgba(202, 255, 250, 0.61);
$color-dropdown-bg: #d9d9d9;
$color-dropdown-entry: #fff;
$color-dropdown-hover: #b6f7f19c;
$color-dropdown-active: #e0e0e0;
$color-assignment-active: #e0e0e0;
$color-assignment-background: #D9D9D9;
$color-table-odd: #EEEEEE;
$color-table-even: #e1e1e1;
$background: linear-gradient(to bottom, #c9e066, #FFFFFF);
;
