
.loader{
  display: block;
  height: 100%;
  width: 60%;
  border: 2px solid #66ecc7;
  background: #66ecc7;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 2px;
}

.loader-wrapper{
  border-radius: 20px;
}
