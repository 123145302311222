@import "src/styles/theme.scss";

.search-bar{
  display: inline-block;
  font-size: large;
}

.type-wrapper{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  margin: 10px;
}

.type-button{
}

.add-wrapper{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 10%;
  // -10px because of padding
  left: calc(35% - 10px);
  top: 20%;
  background-color: #999999;
  border: solid black 1px;
  padding: 10px;
  justify-content: space-evenly;
}

.add-buttons{
  display: flex;
  flex-direction: row;
}

.add-button{
  display: flex;
  width: 50%;
}

.config-wrapper{
  position: absolute;
  width: fit-content;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 20%;
  background-color: #999999;
  border: solid black 1px;
  padding: 10px;
  justify-content: space-evenly;
}

.config-table {
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.config-row {
}

.config-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  height: 50px;
  margin-left: 20%;
  margin-right: 20%;
}

.config-button {
  width: 100px;
}

.value-input-button {
  width: 200px;
}

.param-wrapper{
  position: absolute;
  width: 50%;
  left: calc(25% - 10px);
  top: 20%;
  background-color: #999999;
  border: solid black 1px;
  padding: 10px;
  justify-content: space-evenly;
}

.param-table {
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.param-row {
  height: 50px;
}

.param-cell-key{
  width: 40%;
}

.param-cell-value{
  width: 40%;
}

.param-cell-delete{
  width: 20%;
  text-align: center;
}

.param-key{

}

.param-edit-key{
  width: 90%;
  height: 25px;
  margin-right: 5%;
  margin-left: 5%;
}

.param-delete-key{
  width: 100%;
}

.param-add-key{
  width: 90%;
  height: 25px;
  margin-left: 5%;
  margin-right: 5%;
  padding-left: 0;
  padding-right: 0;
}

.param-buttons{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  justify-content: space-evenly;
}

.param-button{
  width: 25%;
  height: 50px;
}

.param-button-input{
  width: 100%;
  height: 100%;
}

.date-picker{
  display: inline-block;
  width: 200px;
}
