@import "src/styles/theme.scss";

.background{
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 0;
  //background: url(../resources/background.png);
  background-image: $background;
  background-size: cover;
}
