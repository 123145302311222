@import "src/styles/theme.scss";
@import "src/components/inputs/Input.module";

.page{

}

.page-title-input{
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.content-wrapper{

}

.page-wrapper{

}

thead {
  height: 20px;
}

.text-cell-empty{
  height: 22px;
  padding: 0;
}

.table-cell-comment{
  width: calc(100% - 20px);
  margin: 10px;
}

.value-input-comment{
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #d5f1ff;
}

.value-cell{

}

.value-input-row{
  display: flex;
  flex-direction: row;
}
.value-input{
  width: 200px;
}

.value-input-boolean{
  //width: 200px;

}

.value-input-string{
  width: 200px;
}

.value-input-string-wide{
  width: 100%;
}

.value-input-copy-param{
  width: 200px;
}

.value-input-number{
  width: 200px;

}

tr:nth-child(odd) {
  background-color: $color-table-odd;
  width: 100%;
}

tr:nth-child(even) {
  background-color: $color-table-even;
}


.tr:hover {
  //background-color: lighten($main-color, 40%);
}

.title-row{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.title-designtag{
  text-align: center;
  width: 100%;
}

.title-cell{
  display: flex;
  width: 50%;
}

.timeout-view{
  position: absolute;
  width: 100vw;
  height: 100vw;
  left: 0;
  top: 0;
  animation: timeout-background-change 1s linear;
}

@keyframes timeout-background-change {
  0% {background-color: #93ff3f99;}
  100% {background-color: transparent;}

}

// New table cell styles

.table-cell-config,
.table-cell-left-config {
  padding: 10px;
}

.label-config {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}
