@import "src/styles/theme.scss";

.page{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.content-wrapper{
  position: relative;
  margin-left: 10%;
  margin-right: 10%;
  height: 90%;
}

.page-wrapper{
  position: relative;
  height: 100%;
  overflow: scroll;
  background-color: $color-bg;
  border: 1px solid black;
}

table{
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  padding-left: 5px;
  padding-right: 5px;

}

thead{
  background-color: $color-bg-head;
  text-align: center;
  height: 50px;
  position: sticky;
  top: 0;
}

@keyframes fadeRowEven {
  from {
    background-color: $color-fadein;
  }
  to {
    background-color: $color-white;
  }
}

@keyframes fadeRowOdd {
  from {
    background-color: $color-fadein;
  }
  to {
    background-color: $color-bg;
  }
}

.table-row:nth-of-type(even){
  animation: 1s fadeRowEven forwards;
}

.table-row:nth-of-type(odd) {
  animation: 1s fadeRowOdd forwards;
}

.table-cell{
  height: 50px;
  width: 40%;
  text-align: center;
  padding: 0;
  font-weight:500;
}

.table-cell-left{
  height: 50px;
  width: 45%;
  padding: 0;
  font-weight:500;
}

.generic-button-wrapper {
  width: 100%;
  height: 100%;
  //margin: 10px;
  z-index: 1;
}

.generic-button-wrapper-outline {
  @extend .generic-button-wrapper;
  border: solid red 3px;
}

.generic-button {
  height: 40px;
  z-index: 2;
}

.settings-button-row{
  display: flex;
  flex-direction: row;
  z-index: 1;
}

.settings-button{
  display: flex;
  width: 100px;
  z-index: 2;
}

.settings-button-spacer{
  display: flex;
  width: 100px;
  z-index: 2;
}

.dropdown-wrapper{
  display: inline-block;
  background-color: #f6f6f6;
  border: solid grey 1px;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  height: 40px;
  width: 80%;
  text-align: center;
  //margin: auto;
}

.dropdown-wrapper:hover{
  background-color: #dddddd;
  cursor: pointer;
}

.table-row:nth-of-type(even),
.table-row:nth-of-type(odd) {
  transition: background-color 0.3s ease;
}

.generic-button-wrapper,
.dropdown-wrapper {
  background-color: white;
  border: solid #ddd 1px;
  border-radius: 5px;
  width: 90%;
}

.generic-button-wrapper-outline,
.dropdown-wrapper:hover {
  border-color: $main-color;
}

.generic-button,
.dropdown-item {
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 14px;
  cursor: pointer;
}

.generic-button-wrapper:hover,
.dropdown-wrapper:hover {
  background-color: lighten($main-color, 20%);
}
