.button-wrapper{
  position: absolute;
  top: 1%;
  right: 1%;
  border: none;
  padding: 0;
  width: 100px;
  height: 50px;
}

.button{
  width: 100%;
  height: 100%;
}
