@import "src/styles/theme.scss";

.search-bar{
  display: inline-block;
  font-size: large;
  width: 90%;
}

.import-hide{
  //position: static;
  display: none;
}

.import-button{
  display: inline-block;
  height: 100%;
  width: 100%;
  line-height: 100%;
}

.table{
  table-layout: fixed;
}

.table-cell, .table-cell-wide{
  height: 50px;
  line-height: 50px;
  width: 15%;
  text-align: center;
  padding: 0;
  font-weight:500;
  overflow: hidden;
}

.table-cell-wide {
  width: 40%;
}

.type-wrapper{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  margin: 10px;
}

.type-button{
}

.add-wrapper{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 10%;
  // -10px because of padding
  left: calc(35% - 10px);
  top: 20%;
  background-color: #999999;
  border: solid black 1px;
  padding: 10px;
  justify-content: space-evenly;
}

.add-buttons{
  display: flex;
  flex-direction: row;
}

.add-button{
  display: flex;
  width: 50%;
}


@media screen and (max-width: 600px) {
  .table-cell-wide{
    width: 40%;
  }
  .table-cell {
    width: 15%;
  }
}
