
.spinner{
  display: block;
  //display: inline-block;

  //overflow: auto;
  height: 100%;
  width: 100%;
  //object-fit: cover;
  border: 5px solid #0000FF;
  border-bottom-color: transparent;
  border-radius: 50%;
  //display: inline-block;
  //box-sizing: border-box;
  animation: rotation 1s linear infinite;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
