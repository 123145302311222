.columnWrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 80%;
  left: 10%;
  top: 10%;
}

.column {
  width: 40%;
  margin-left: 5%;
  margin-right: 5%;
}

.columnHeader {
  background-color: rgb(233, 235, 241);
  padding: 5px;
  padding-bottom: 2%;
  margin-bottom: 2%;

  border-radius: 15px;
  box-shadow: 5px 5px 5px #888888;
  z-index: 1;
}

.columnHeaderCaption {
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

.cells {
  overflow: scroll;
  padding: 2%;
  max-height: 70vh;
  padding-bottom: 10%;
  -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  //mask-image: linear-gradient(to bottom, black 2%, transparent 100%);
}

.cell {
  background-color: rgb(233, 235, 241);
  padding: 5px;
  padding-left: 20px;
  padding-top: 10px;
  margin-top: 2%;

  border-radius: 15px;
  box-shadow: 5px 5px 5px #888888;
}


.cellRow {
  display: flex;
  flex-direction: row;
}

.cellTextCaption {
  font-weight: bold;
  padding-right: 5px;
  margin: 0 0 10px;
}

.cellTextContent {
  margin: 0 0 10px;
}
