@import "src/styles/theme.scss";

.add-wrapper{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 10%;
  // -10px because of padding
  left: calc(35% - 10px);
  top: 20%;
  background-color: #999999;
  border: solid black 1px;
  padding: 10px;
  justify-content: space-evenly;
}

.add-buttons{
  display: flex;
  flex-direction: row;
}

.add-button{
  display: flex;
  width: 50%;
}

.table{
  text-align: center;
  border-collapse: collapse;
}


.tbody tr:first-child td {
  display: table-cell;
  border-bottom: solid black 1px;
  height: 50px
}



.tr:nth-child(odd) {
  background-color: $color-table-odd;
}

.tr:nth-child(even) {
  background-color: $color-table-even;
}

.td-border{
  border-right: solid black 1px;
}

.td-tag{

}

.td-value{

}

.td-button{

}
